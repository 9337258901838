import React, { useEffect } from 'react';
import { H3, P } from '../../../components/Headings';
import { BackArrow } from '../../../assets/icons';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { routes } from '../../../router/routes';
import CalendarWidget from '../../../components/elements/CalendarWidget';
import manStandDumbell from '../../../assets/images/man_stand_dumbell.png';
import { useState } from 'react';
import { Button } from '@deposits/ui-kit-react';
import DurationTimePicker from '../../../components/sections/explore/DurationTimePicker';
import { plans } from '../../../utils/dummyData';
import { useCookies } from 'react-cookie';
import {
  useSessions,
  useTotalCoins,
  useTotalSessions,
} from '../../../helpers/hooks/queries/useSessions';
import { supabase } from '../../../utils/supabaseConfig';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useQueryClient } from 'react-query';
import {
  FunctionsFetchError,
  FunctionsHttpError,
  FunctionsRelayError,
} from '@supabase/supabase-js';

export const formatTime = (time) => {
  if (time === 24) {
    return `12:00 AM`;
  } else if (time === 12) {
    return `12:00 PM`;
  } else if (time > 12) {
    return `${time - 12}:00 PM`;
  } else {
    return `${time}:00 AM`;
  }
};

const PlanCard = ({
  id,
  name,
  desc,
  startTime,
  endTime,
  fiat_price,
  coin_price,
  selected,
  setSelected,
  setSelectedTime,
  setSelectedDuration,
}) => {
  return (
    <div
      role="button"
      className={` border border-gray-4 rounded-2xl min-h-36 w-full lg:h-[174px] lg:w-[446px] p-4 lg:p-6 gap-x-3 space-y-4 lg:space-y-8 text-renaissance-black dark:text-renaissance-dark-black  
      ${selected === id && ' bg-primary-green/30'}`}
      onClick={() => {
        setSelectedTime(null);
        setSelectedDuration(0);
        setSelected(id);
      }}
    >
      <section className={`flex`}>
        <div className="flex-1 space-y-1 lg:space-y-4">
          <h5 className="text-base lg:text-lg font-semibold"> {name} </h5>
          <p className="text-primary-gray font-normal text-sm lg:text-sm text-ellipsis">
            {' '}
            {desc}{' '}
          </p>
        </div>
        <div>
          <p className="flex items-baseline text-primary-gray">
            <span className="self-start pr-0.5 text-xs lg:text-base">£</span>
            <span className="font-bold font-droid text-[32px] lg:text-5xl text-renaissance-black dark:text-renaissance-dark-black">
              {' '}
              {fiat_price}{' '}
            </span>
            /hour
          </p>
        </div>
      </section>

      <section className="flex items-baseline justify-between ">
        <div className="flex-1 flex items-baseline  gap-x-1 lg:gap-x-8">
          <div className="bg-badge-gray font-normal py-0.5 px-2 text-xxs rounded-xlg text-neutral-700">
            Available Time
          </div>
          <p className="font-semibold text-xxs lg:text-xs">
            {formatTime(startTime) + ' - ' + formatTime(endTime)}
          </p>
        </div>

        <div className="font-semibold text-xs">{coin_price} coins/hr</div>
      </section>
    </div>
  );
};

const BookNew = () => {
  let { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(state?.planId || 0);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedDuration, setSelectedDuration] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const { data: dataSessions } = useSessions();
  const { data } = useTotalSessions();
  const { data: dataCoins } = useTotalCoins();

  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const queryClient = useQueryClient();

  const username = searchParams.get('username');
  const userId = searchParams.get('userId');

  const toggleModal = () => {
    setModalOpen((prev) => !prev);
  };

  const createSession = async () => {
    const { data: user, error: er } = await supabase.auth.admin.getUserById(
      userId,
    );

    const { user_metadata } = user.user;

    const plan = plans[selectedPlan - 1];
    const { name, coin_price } = plan;
    const start = [
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      selectedDate.getDate(),
      selectedTime,
    ];
    const end = [
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      selectedDate.getDate(),
      selectedTime + selectedDuration,
    ];

    let endTime;

    // if user picks 11pm start time, make end time 11.45 instead of midnight
    if (selectedTime + selectedDuration === 24) {
      let calculatedTime = moment(start);
      calculatedTime.hour(23).minute(45).second(0).millisecond(0);

      endTime = moment(calculatedTime).format('YYYY-MM-DDTHH:mm:ss');
    } else {
      endTime = moment(end).format('YYYY-MM-DDTHH:mm:ss');
    }

    const submit = {
      user_id: userId,
      username,
      email: user_metadata.email,
      payment: 'coin balance',
      amount: coin_price * selectedDuration,
      type: name,
      date: moment(selectedDate).format('YYYY-MM-DD'),
      duration: `${selectedDuration} hours`,
      startTime: moment(start).format('YYYY-MM-DDTHH:mm:ss'),
      endTime: endTime,
    };

    setLoading(true);

    const { data, error } = await supabase.functions.invoke(
      'admin-create-session',
      {
        body: JSON.stringify({
          session: { ...submit },
        }),
      },
    );

    if (!error) {
      queryClient.invalidateQueries('all');
      queryClient.invalidateQueries('sessions');
      queryClient.invalidateQueries('all-sessions');
      queryClient.invalidateQueries('total-active-sessions');
      queryClient.invalidateQueries('total-sessions');
      setLoading(false);
      setSelectedPlan(0);
      toast.success('Created session Successfully');
      toggleModal();
      navigate(`/dashboard/${routes.session}/${routes.allSessions}`);
    } else {
      setLoading(false);
      if (error instanceof FunctionsHttpError) {
        const errorMessage = await error.context.json();
        toast.error(errorMessage?.message);
      } else if (error instanceof FunctionsRelayError) {
        toast.error(error.message);
      } else if (error instanceof FunctionsFetchError) {
        toast.error(error.message);
      } else {
        toast.error('Failed to save session');
      }
    }
  };

  return (
    <div className=" text-renaissance-black dark:text-renaissance-dark-black !px-6 lg:!pl-6 xl:!pl-12  pt-6 pb-24 transition w-full ">
      <section className="">
        <H3> Book a session </H3>
        <P className="  ">
          After booking a session, you will be able to see extend your session
          by 30 mins at half your booking fee
        </P>
      </section>

      <section className="py-10 ">
        <Link to={`/${routes.dashboard_home}/${routes.session}`}>
          <img src={BackArrow} alt="back arrow" />
        </Link>
      </section>

      <section className="flex lg:flex-row flex-col items-center lg:items-start gap-y-20 lg:gap-y-0 justify-between ">
        <div className=" max-w-sm flex- text-center lg:text-left">
          <div>
            <H3 className={`mb-10`}>Select Date and Time</H3>
            {/* <P className="pt-2 pb-10">
                In your local time GMT +8
                <span className="text-renaissance-blue pl-2"> Update </span>
              </P> */}
            <CalendarWidget
              setDateValue={setSelectedDate}
              dateValue={selectedDate}
            />
          </div>

          <div className="mt-20 text-left hidden lg:block ">
            {!selectedPlan ? (
              <div className="flex items-center gap-5 w-full">
                <p> Choose preferred session to see available time </p>
                <img src={manStandDumbell} alt="manStandDumbell" />
              </div>
            ) : (
              <DurationTimePicker
                selectedPlan={selectedPlan}
                setSelectedPlan={setSelectedPlan}
                setSelectedDate={setSelectedDate}
                selectedDate={selectedDate}
                selectedTime={selectedTime}
                setSelectedTime={setSelectedTime}
                selectedDuration={selectedDuration}
                setSelectedDuration={setSelectedDuration}
                submitHandler={createSession}
                coinBalance={dataCoins}
              />
            )}
          </div>
        </div>

        <div className="flex-1 ">
          <div className="  flex-1 max-w-max mx-auto ">
            <H3 className={`mb-10`}>Pay-As-You-Go</H3>
            {/* <P className="pt-2 pb-10">
                In your local time GMT +8
                <span className="text-renaissance-blue pl-2"> Update </span>
              </P> */}

            <div className="space-y-6">
              {plans.map((plan) => (
                <PlanCard
                  key={plan.id}
                  {...plan}
                  selected={selectedPlan}
                  setSelected={setSelectedPlan}
                  setSelectedTime={setSelectedTime}
                  setSelectedDuration={setSelectedDuration}
                />
              ))}
            </div>

            <div className="mt-20 text-left lg:hidden">
              {!selectedPlan ? (
                <div className="flex items-center gap-5 w-full">
                  <p> Choose preferred session to see available time </p>
                  <img src={manStandDumbell} alt="manStandDumbell" />
                </div>
              ) : (
                <DurationTimePicker
                  selectedPlan={selectedPlan}
                  setSelectedPlan={setSelectedPlan}
                  setSelectedDate={setSelectedDate}
                  selectedDate={selectedDate}
                  selectedTime={selectedTime}
                  setSelectedTime={setSelectedTime}
                  selectedDuration={selectedDuration}
                  setSelectedDuration={setSelectedDuration}
                  submitHandler={createSession}
                  coinBalance={dataCoins}
                  isLoading={loading}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BookNew;
